<template>
	<q-menu class="bg-grey-9 text-grey-3 py3" anchor="top right" self="top left" >
		<q-list style="min-width: 160px">
			<q-item clickable @click="goto_link(item)" v-for="item in menu.children">
				<q-item-section>{{item.title}}</q-item-section>
				<template v-if="item.children.length>0">
					<q-item-section side >
						<q-icon name="keyboard_arrow_right" ></q-icon>
					</q-item-section>
					<com-left_pop_menu :menu="item"></com-left_pop_menu>
				</template>
			</q-item>
		</q-list>
	</q-menu>
</template>

<script>
export default {
	name : "com-left_pop_menu",
	props : {
		menu : Object,
	},
	data : function() {
		return {}
	},
	methods : {
		goto_link : function(menu) {
			if(menu.children.length==0 && menu.link) {
				this.$router.push(menu.link);
			}
		},
	},
}
</script>

