
import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
		  primary: '#1976d2', secondary: '#26A69A', accent: '#9C27B0', dark: '#1d1d1d', 
		  positive: '#07BF6F', negative: '#C10015', info: '#31CCEC', warning: '#F2C037'
		}
  },
  plugins: {
	  Notify,
    Dialog,
	  Loading
  }
}