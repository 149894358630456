var $sns = (function() {
	var self = {
		host : '', //appvue.$route.path
		click_recommend : function(product_id, callback) {
			if( $app.user_id>0 ) {
				$c.io.post( self.host+'/account/product_follow/a_recommend', {'product_id':product_id}).then( function(data) {
					$c.notify.show(data);
					if( data['success'] ) {
						if(callback) { callback(data); }
					}
				});
			} else {
				$c.notify.show({success:0, message: '로그인후 추천해주세요.' });
			}
		},
		click_un_recommend : function(product_id, callback) {
			if( $app.user_id>0 ) {
				$c.io.post( self.host+'/account/product_follow/a_un_recommend', {'product_id':product_id}).then( function(data) {
					$c.notify.show(data);
					if( data['success'] ) {
						if(callback) { callback(data); }
					}
				});
			}
		},
		click_scrap : function(product_id, callback) {
			if( $app.user_id>0 ) {
				$c.io.post( self.host+'/account/product_scrap/a_scrap', {'product_id':product_id}).then( function(data) {
					$c.notify.show(data);
					if( data['success'] ) {
						if(callback) { callback(data); }
					}
				});
			} else {
				$c.notify.show({success:0, message: '로그인후 클릭하세요.' });
			}
		},
		click_un_scrap : function(product_id, callback) {
			if( $app.user_id>0 ) {
				$c.io.post( self.host+'/account/product_scrap/a_un_scrap', {'product_id':product_id}).then( function(data) {
					$c.notify.show(data);
					if( data['success'] ) {
						if(callback) { callback(data); }
					}
				});
			}
		},
		
		
		click_recommend_post : function(article_id, callback) {
			if( $app.user_id>0 ) {
				$c.io.post( self.host+'/account/post_follow/a_recommend', {'article_id':article_id}).then( function(data) {
					$c.notify.show(data);
					if( data['success'] ) {
						if(callback) { callback(data); }
					}
				});
			} else {
				$c.notify.show({success:0, message: '로그인후 추천해주세요.' });
			}
		},
		click_un_recommend_post : function(article_id, callback) {
			if( $app.user_id>0 ) {
				$c.io.post( self.host+'/account/post_follow/a_un_recommend', {'article_id':article_id}).then( function(data) {
					$c.notify.show(data);
					if( data['success'] ) {
						if(callback) { callback(data); }
					}
				});
			}
		},
		click_scrap_post : function(article_id, callback) {
			if( $app.user_id>0 ) {
				$c.io.post( self.host+'/account/post_scrap/a_scrap', {'article_id':article_id}).then( function(data) {
					$c.notify.show(data);
					if( data['success'] ) {
						if(callback) { callback(data); }
					}
				});
			} else {
				$c.notify.show({success:0, message: '로그인후 클릭하세요.' });
			}
		},
		click_un_scrap_post : function(article_id, callback) {
			if( $app.user_id>0 ) {
				$c.io.post( self.host+'/account/post_scrap/a_un_scrap', {'article_id':article_id}).then( function(data) {
					$c.notify.show(data);
					if( data['success'] ) {
						if(callback) { callback(data); }
					}
				});
			}
		},
		
		/* 공유기능 */
		click_ShareNaver: function(url, title, text) { //title->하모교육뉴스
			var option = "left=50,top=50,width=600,height=450,scrollbars=1,menubar=no,toolbar=no,resizable=yes";
			var hamo_text = "<a href='"+url+"'>"+title+"</>";
			var _url = encodeURIComponent(url);
			var _text = encodeURIComponent(text);
			window.open('http://share.naver.com/web/shareView.nhn?url='+_url+'&title='+_text, 'naversharedialog', option);
 		},
		click_ShareFacebook: function(url, title, text) {
			var option = "left=50,top=50,width=600,height=450,scrollbars=1,menubar=no,toolbar=no,resizable=yes";
			var hamo_text = "<a href='"+url+"'>"+title+"</>";
			var _url = encodeURIComponent(url);
			var _text = encodeURIComponent(text+" "+hamo_text);
			window.open('https://www.facebook.com/sharer/sharer.php?u='+_url+'&t='+_text, 'facebooksharedialog', option);
		},
		click_ShareTwitter: function(url, title, text) {
			var option = "left=50,top=50,width=600,height=300,scrollbars=1,menubar=no,toolbar=no,resizable=yes";
			var hamo_text = "\n"+url;
			var _text = encodeURIComponent(text+" "+hamo_text);
			window.open('https://twitter.com/intent/tweet?text='+_text, 'twittersharedialog', option);
		},
		click_ShareGoogle: function(url, title, text) {
			var option = "left=50,top=50,width=400,height=500,scrollbars=1,menubar=no,toolbar=no,resizable=yes";
			var hamo_text = "<a href='"+url+"'>"+title+"</>";
			var _url = encodeURIComponent(url);
			var _text = encodeURIComponent(text+" "+hamo_text);
			window.open('https://plus.google.com/share?url='+_url, 'googleplussharedialog', option);
		},
	};
	return self;
})();
