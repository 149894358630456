<template>
	<div>
		<div class="container404">
			<h1>404</h1>
			<p> </p>
			<p><strong>죄송합니다. 요청하신 페이지를 찾을 수 없습니다.</strong></p>
			<p>
				만약, 다른 페이지를 찾으신다면, <q-btn color="primary" size="sm" class="px3" label="홈" @click="goto('/')" />에서부터 시작하여 메뉴를 탐색하세요.
			</p>
		</div>
	</div>
</template>

<style>
body {
    background-color: #f1f1f1;
	height:unset;
}
.container404 {
    margin: 50px auto 40px auto;
    width: 600px;
    text-align: center;
}
.container404 h1 {
	width: 800px;
    position: relative;
    left: -100px;
    letter-spacing: -1px;
    line-height: 60px;
    font-size: 60px;
    font-weight: 100;
    margin: 0px 0 50px 0;
    text-shadow: 0 1px 0 #fff;
}
.container404 p {
    color: rgba(0, 0, 0, 0.5);
    margin: 20px 0;
    line-height: 1.6;
}
</style>

<script>
export default {
    name: 'com_404',
	data : function() {
        return {
        }
    },
    methods : {
		goto: function(url) {
			this.$router.push(url);
		}
	},
	mounted : function() {
		console.log('404');
	}
}
</script>